import React from "react";

import Layout from "../components/page/Layout";
import Logout from "../components/user-management/Logout";

const LogoutPage = () => {
  return (
    <div>
      <h2>Logout</h2>
      <Logout />
    </div>
  );
};

export default LogoutPage;
