import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby-link";

import { authenticationService } from "../../_services";
import { Context } from "../store";
import { addError } from "../../utils/errors";
import WaitingAnimation from "../WaitingAnimation";
import { removeLocalStorageItem } from "../../_services/localStorageService";

const Logout = () => {
  const [state] = useState({ logged_out: false });
  const [, setContext] = useContext(Context);

  useEffect(() => {
    async function logout() {
      authenticationService.logout().catch((err) => {
        addError(setContext, "Could not contact the server for logout");
      });

      removeLocalStorageItem("currentUser");
      // localStorage.removeItem("currentUser")
      navigate("/");
    }
    logout();
  }, [setContext]);

  return <div>{!state.logged_out ? <WaitingAnimation /> : null}</div>;
};

export default Logout;
